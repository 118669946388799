<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·跟进详细信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="follow-moreInfo">
    <div class="follow-moreInfo">
      <el-dialog
        title="跟进详细信息"
        class='yourName dialogTwo'
        size="tiny"
        :visible.sync="test"
        :before-close="flInfoClose"
        :modal="false"
        :modal-append-to-body="true"
        :lock-scroll="true"
        :close-on-click-modal="false">
        <!--此处添加你的弹框内容-->
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td class="flTitle">跟进方式</td>
            <td>{{fInfo.mode}}</td>
          </tr>
          <tr>
            <td class="flTitle">跟进时间</td>
            <td>{{fInfo.Date}}</td>
          </tr>
          <tr>
            <td class="flTitle">提醒时间</td>
            <td>{{fInfo.remindDate}}</td>
          </tr>
          <tr>
            <td class="flTitle">销售进程</td>
            <td>{{fInfo.course}}</td>
          </tr>
          <tr class="last-title">
            <td class="flTitle">跟进内容</td>
            <td>{{fInfo.content}}</td>
          </tr>
        </table>
        <!--底部确认取消按钮
        <span slot="footer" class="dialog-footer">
          <el-button @click="flInfoClose">关 闭</el-button>
        </span>-->
      </el-dialog>
    </div>
  </div>
</template>

<script>
  export default{
    name:"follow-moreInfo",
    data() {
      return {
        test:true
      }
    },
    methods:{
    	//触发父组件关闭弹窗
      flInfoClose(){
        this.$emit('flInfoClose')
      }
    },
    props:[
      'fInfo'
    ],
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  }
</script>

<style lang="less">
  #follow-moreInfo{
    .dialog-masks{
      z-index: 2001;
      background:rgba(0,0,0,0.2);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      overflow: auto;
      margin: 0;
    }
    .follow-moreInfo{
      /*position:fixed;*/
      /*z-index:2002;*/
    }
    .el-dialog{
      width:500px;
    }
    .el-dialog__body{
      table{
        border:1px solid #ddd;
        .flTitle{
          width:100px;
          background-color: #f2f2f2;
          border-left:0;
        }
        td{
          height:40px;
          line-height: 40px;
          text-align: left;
          text-indent: 10px;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
      .last-title{
        td{
          border-bottom:0;
        }
      }
    }
  }
</style>
