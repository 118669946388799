<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·订单详情信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="compelete-record-info">
    <el-dialog
      title="订单详情信息"
      class='dialogTwo compelete-info'
      size="tiny"
      :visible.sync="test"
      :before-close="cntClose"
      :modal="false"
      :modal-append-to-body="true"
      :lock-scroll="true"
      :close-on-click-modal="false">
        <div>
          <table width="100%" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td class="flTitle">订单类型</td>
              <td>{{records_data.indent_type}}</td>
            </tr>
            <tr>
              <td class="flTitle">成交时间</td>
              <td>{{records_data.compelete_date}}</td>
            </tr>
            <tr>
              <td class="flTitle">币种</td>
              <td>{{records_data.r_currency}}</td>
            </tr>
            <tr>
              <td class="flTitle">成交金额</td>
              <td>{{records_data.compelete_sum}}</td>
            </tr>
            <tr>
              <td class="flTitle">购买数量</td>
              <td>{{records_data.buy_num}}</td>
            </tr>
            <tr>
              <td class="flTitle">备注信息</td>
              <td>{{records_data.remark}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </el-dialog>
  </div>
</template>
<script>
  export default({
    data(){
      return{
        test:true,
      }
    },
    methods:{
    	//触发父组件关闭弹窗
      cntClose(){
        this.$emit('srbClose');
      }
    },
    props:[
      'records_data'
    ],
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  })
</script>
<style lang="less">
@backGray:#eef1f6;
@borderGray:#d3d3d3;
#compelete-record-info{
  .compelete-info{
    .el-dialog.el-dialog{
      width:600px;
      .el-dialog__body{
        overflow:auto;
        table{
          border:1px solid #ddd;
          .flTitle{
	          width:100px;
	          background-color: #f2f2f2;
	          border-left:0;
	        }
          td{
	          height:40px;
	          line-height: 40px;
	          text-align: left;
	          text-indent: 10px;
	          border-left: 1px solid #ddd;
	          border-bottom: 1px solid #ddd;
	        }
        }
      }
    }
  }
}
</style>
