<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：客户·客户详细信息·商机详情信息弹窗
开始时间：2017-11-9
开发人员：陈立珍
最后修改：2018-7-23
备注说明：如需修改请联系开发人员
-->

<template>
  <div id="business-detail">
    <el-dialog
      title="商机详情信息"
      class='dialogTwo business-detail'
      size="tiny"
      :visible.sync="test"
      :before-close="cntClose"
      :modal="false"
      :modal-append-to-body="true"
      :lock-scroll="true"
      :close-on-click-modal="false">
        <div>
          <table width="100%" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td class="flTitle">机会来源</td>
              <td>{{business_data.chance_origin}}</td>
            </tr>
            <tr>
              <td class="flTitle">机会可能性</td>
              <td v-if="business_data.success_odds == 1">
              	<div style="margin-left: 10px;">
              		<b class="star"></b>
              	</div>
              </td>
              <td v-else-if="business_data.success_odds == 2">
              	<div style="margin-left: 10px;">
              	<b class="star"></b>
              	<b class="star"></b>
              	</div>
              </td>
              <td v-else-if="business_data.success_odds == 3">
              	<div style="margin-left: 10px;">
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	</div>
              </td>
              <td v-else-if="business_data.success_odds == 4">
              	<div style="margin-left: 10px;">
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	</div>
              </td>
              <td v-else-if="business_data.success_odds == 5">
              	<div style="margin-left: 10px;">
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	<b class="star"></b>
              	</div>
              </td>
              <td v-else="">
              	
              </td>
            </tr>
            <tr>
              <td class="flTitle">成交币种</td>
              <td>{{business_data.r_currency}}</td>
            </tr>
            <tr>
              <td class="flTitle">预成交金额</td>
              <td>{{business_data.compelete_sum}}</td>
            </tr>
            <tr>
              <td class="flTitle">预成交时间</td>
              <td>{{business_data.compelete_time}}</td>
            </tr>
            <tr>
              <td class="flTitle">当前状态</td>
              <td>{{business_data.now_status}}</td>
            </tr>
            <tr>
              <td class="flTitle">备注信息</td>
              <td>{{business_data.remark}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </el-dialog>
  </div>
</template>
<script>
  export default({
    data(){
      return{
        test:true,
        
      }
    },
    methods:{
    	//触发父组件关闭弹窗
      cntClose(){
        this.$emit('cntClose');
      }
    },
    props:[
      'business_data'
    ],
    mounted(){
      function clearTransform(){
        $('.el-dialog').css({
          'transform': 'none',
        })
      };
      dialogDrag();
      clearTransform(dialogCenter());
    }
  })
</script>
<style lang="less">
@backGray:#eef1f6;
@borderGray:#d3d3d3;
#business-detail{
  .business-detail{
    .el-dialog.el-dialog{
      width:600px;
      .el-dialog__body{
        overflow:auto;
        table{
          border:1px solid #ddd;
          .flTitle{
	          width:100px;
	          background-color: #f2f2f2;
	          border-left:0;
	        }
          td{
	          height:40px;
	          line-height: 40px;
	          text-align: left;
	          text-indent: 10px;
	          border-left: 1px solid #ddd;
	          border-bottom: 1px solid #ddd;
	        }
	        .star {
					    float: left;
					    margin-right: 5px;
					    background: url('../../../../static/img/common/star.png') no-repeat;
					    display: block;
					    width: 12px;
					    height: 12px;
					}
        }
      }
    }
  }
}
</style>
