// 程序版本：V2.0
// 版权所有：深圳市科飞时速网络科技有限公司
// 技术支持：Tech@21gmail.com
// 单元名称：公用组件中.意向产品查看js
// 开始时间：2019-11-20
// 开发人员：吴荣江
// 最后修改：2019-11-20
// 备注说明：如需修改请联系开发人员
import popupLoading from '@/components/publicComponent/popupLoading'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
export default {
    name: "lookProduct",
    data() {
        return {
            proTest: false, //弹窗
            showChoiceProduct: [],//显示选中的产品
            loading: false,//产品加载
            
        }
    },
    components: {
        popupLoading
    },
    props: {
        choiceProductArr: {
            default: []
        },
    },
    methods: {
        //通过父组件返回的id来判断当前产品
        showProductData() {
            let self = this
            let arr = []
            let arr2 = []
            let index = 0
            self.showChoiceProduct = []
            //判断当前是数组还是string
            if (typeof this.choiceProductArr == 'string') {
                if (this.choiceProductArr.indexOf(",") != -1) {
                    arr = this.choiceProductArr.split(",")
                } else {
                    if (this.choiceProductArr != '') {
                        arr.push(Number(this.choiceProductArr))
                    }
                }
                $.each(arr, (i, elem) => {
                    arr2.push(Number(elem))
                })
            } else {
                arr2 = this.choiceProductArr
            }
            if (arr2.length > 0) {
                this.loading = true
            }
            //循环当前的产品
            $.each(arr2, (i, elem) => {
                if (elem != 0) {
                    $.ajax({
                        type: 'POST',
                        url: backUrl + 'cusXPCS_findXProductByid.action',
                        data: {
                            token: token,
                            productclassifyid: Number(elem)
                        },
                        success: (res) => {
                            if (res !== undefined && res !== null && res != '') {
                                self.showChoiceProduct.push({
                                    id: res.id,
                                    name: res.name,
                                    code: res.product_code
                                })
                            }
                            index += 1
                            if (index == arr.length) {
                                self.loading = false
                            }
                        }
                    })
                } else {
                    index += 1
                    if (index == arr.length) {
                        self.loading = false
                    }
                }
            })
        },
        //点击查看产品
        showProduct(){
            this.proTest=true
        },
        //关闭查看产品弹框
        closeProduct(){
            this.proTest=false
        }
    },
    mounted: function () {
        function clearTransform() {
            $('.el-dialog').css({
                'transform': 'none',
            })
        };
        dialogDrag();
        clearTransform(dialogCenter());
        //进入组件的时候加载产品信息
        this.showProductData()
    },
    filters: {
        getValue(value) { //无数据的时候返回值
            if (value == '' || value === null || value == undefined || value == 0) {
                return "无"
            } else {
                return value
            }
        },
    },
    watch:{
		choiceProductArr:function(val){
            this.showProductData()
        }
	}
}